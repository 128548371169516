<template>
  <div>
    <header-slot>
      <template #actions>
        <portal-target name="settings-actions" />
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <template v-for="(item, index) in navItems">
        <b-nav-item
          :to="{ name: mixRouteName(item.route) }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          :key="index"
        >
          {{ item.text_display }}
        </b-nav-item>
      </template>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name"></router-view>
    </b-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navItems: [
        {
          text_display: "Types of Claims",
          route: "-s-type-request",
        },
      ],
    };
  },
  methods: {
    mixRouteName(route) {
      return this.getModuleName(this.$route.matched[0].meta.module) + route;
    },
  },
};
</script>